import { blue700 } from 'material-ui/styles/colors';
import AwSkipNext from 'material-ui/svg-icons/action/assignment-return';
import CreditCardIcon from 'material-ui/svg-icons/action/credit-card';
import DnsIcon from 'material-ui/svg-icons/action/dns';
import HomeIcon from 'material-ui/svg-icons/action/home';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import EqualizerIcon from 'material-ui/svg-icons/av/equalizer';
import WebAssetIcon from 'material-ui/svg-icons/av/web-asset';
import ImportContactsIcon from 'material-ui/svg-icons/communication/import-contacts';
import ImportExportIcon from 'material-ui/svg-icons/communication/import-export';
import LowPriorityIcon from 'material-ui/svg-icons/content/low-priority';
import Mail from 'material-ui/svg-icons/content/mail';
import KeyboardCapslockIcon from 'material-ui/svg-icons/hardware/keyboard-capslock';
import CustomerGroupIcon from 'material-ui/svg-icons/social/group';
import DirectionsBus from 'material-ui/svg-icons/maps/directions-bus';
import CalendarMonthIcon from 'material-ui/svg-icons/action/event';
import React from 'react';
import { StoreModuleType } from 'src/components/administration/operator-store/types';

const iconMap: { [key in StoreModuleType]?: JSX.Element } = {
    BASIC_SYSTEM_FEE: <DnsIcon color={blue700} />,
    ARRIVAL_RETURN_FLAT_RATE_CALCULATION: <AwSkipNext color={blue700} />,
    BUS_DISCOUNT_GROUP: <CustomerGroupIcon color={blue700} />,
    CALCULATION_SKIP_AVAILABILITY_CHECK: <LowPriorityIcon color={blue700} />,
    CUSTOM_MAIL_SERVER: <Mail color={blue700} />,
    FURTHER_BASES: <HomeIcon color={blue700} />,
    IMPORT_RATIO_CUSTOMER: <ImportExportIcon color={blue700} />,
    INTEGRATION_STATISTICS: <EqualizerIcon color={blue700} />,
    INTERFACE_CS_BUS: <WebAssetIcon color={blue700} />,
    INTERFACE_DELTAPLAN: <WebAssetIcon color={blue700} />,
    INTERFACE_KUSCHICK: <WebAssetIcon color={blue700} />,
    INTERFACE_RATIO: <WebAssetIcon color={blue700} />,
    INTERFACE_TURISTA: <WebAssetIcon color={blue700} />,
    OCCUPANCY_SURCHARGES: <ImportContactsIcon color={blue700} />,
    ONLINE_PAYMENT: <CreditCardIcon color={blue700} />,
    PTV_ROUTE_CALCULATION: <KeyboardCapslockIcon color={blue700} />,
    ENHANCED_BUS_PLACEMENT: <DirectionsBus color={blue700} />,
    ICS_FILE_BOOKING_CONFIRMATION: <CalendarMonthIcon color={blue700} />,
};

interface CardIconProps {
    type: StoreModuleType;
}

export const CardIcon = ({ type }: CardIconProps) => iconMap[type] || null;
