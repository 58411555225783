import React from 'react';

const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    marginBottom: '2rem',
};

const storeContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5rem',
    justifyContent: 'flex-start',
};

interface StoreContainerProps {
    title: string;
}

export const StoreContainer: React.FC<StoreContainerProps> = ({ children, title }) => (
    <div style={containerStyle}>
        <h4>{title}</h4>
        <div style={storeContainerStyle}>{children}</div>
    </div>
);
