import BigNumber from 'bignumber.js';
import Money, { Currency } from 'src/common/entity/basic-types/Money';

/**
 * Holds all account types
 * @type {{CUSTOMER: string, DRIVER: string, OPERATOR: string, DISPATCHER: string, ADMIN: string, SUPER_ADMIN: string}}
 */
export enum UserRole {
    CUSTOMER = 'CUSTOMER',
    DRIVER = 'DRIVER',
    OPERATOR = 'OPERATOR',
    TRIP_OPERATOR = 'TRIP_OPERATOR',
    DISPATCHER = 'DISPATCHER',
    ADMIN = 'ADMIN',
    SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum StatusType {
    SUCCESS = 'SUCCESS',
    NOTIFY = 'NOTIFY',
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

export enum ModuleType {
    ARRIVAL_RETURN_FLAT_RATE_CALCULATION = 'ARRIVAL_RETURN_FLAT_RATE_CALCULATION',
    BUS_DISCOUNT_GROUP = 'BUS_DISCOUNT_GROUP',
    CALCULATION_SKIP_AVAILABILITY_CHECK = 'CALCULATION_SKIP_AVAILABILITY_CHECK',
    CUSTOM_MAIL_SERVER = 'CUSTOM_MAIL_SERVER',
    FURTHER_BASES = 'FURTHER_BASES',
    IMPORT_RATIO_CUSTOMER = 'IMPORT_RATIO_CUSTOMER',
    INTEGRATION_STATISTICS = 'INTEGRATION_STATISTICS',
    INTERFACE_CS_BUS = 'INTERFACE_CS_BUS',
    INTERFACE_DELTAPLAN = 'INTERFACE_DELTAPLAN',
    INTERFACE_KUSCHICK = 'INTERFACE_KUSCHICK',
    INTERFACE_RATIO = 'INTERFACE_RATIO',
    INTERFACE_TURISTA = 'INTERFACE_TURISTA',
    OCCUPANCY_SURCHARGES = 'OCCUPANCY_SURCHARGES',
    ONLINE_PAYMENT = 'ONLINE_PAYMENT',
    PTV_ROUTE_CALCULATION = 'PTV_ROUTE_CALCULATION',
    ENHANCED_BUS_PLACEMENT = 'ENHANCED_BUS_PLACEMENT',
    ICS_FILE_BOOKING_CONFIRMATION = 'ICS_FILE_BOOKING_CONFIRMATION',
}

export const BASIC_SYSTEM_FEE_MODULE = 'BASIC_SYSTEM_FEE';
export const BASIC_SYSTEM_FEE = new Money(new BigNumber(200), Currency.EUR);

export enum TripCategoryGroup {
    ACCOMMODATION = 'ACCOMMODATION',
    ACTIVITY = 'ACTIVITY',
    FOOD = 'FOOD',
}
