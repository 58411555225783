import React from 'react';
import { IndexRedirect, IndexRoute, Route } from 'react-router';
import App from 'src/components/app';
import Home from 'src/components/nav/home';
import Administration from 'src/components/administration/administration';
import CompanyAdministrationContainer from 'src/components/administration/company-administration-container';
import BusAdministrationContainer from 'src/components/administration/bus-administration-container';
import CategoryAdministrationContainer from 'src/components/administration/category-administration-container';
import CalculationAdministrationContainer from 'src/components/administration/calculation-administration-container';
import SeasonCalculationAdministrationContainer from 'src/components/administration/season-calculation-administration-container';
import CountryCalculationAdministrationContainer from 'src/components/administration/country-calculation-administration-container';
import DriverAdministrationContainer from 'src/components/administration/driver-administration-container';
import TemplateAdministration from 'src/components/administration/template-administration';
import OperatingToolContainer from 'src/components/operatingtool/operating-tool-container';
import AdminCompanyAdministrationContainer from 'src/components/admin/companies/admin-company-administration-container';
import AdminAccountAdministrationContainer from 'src/components/admin/accounts/admin-account-administration-container';
import AdminCustomerAdministrationContainer from 'src/components/admin/accounts/admin-customer-administration-container';
import AdminEquipmentAdministrationContainer from 'src/components/admin/equipments/admin-equipment-administration-container';
import AdminBrandAdministrationContainer from 'src/components/admin/brands/admin-brand-administration-container';
import AdminAgeRatingAdministrationContainer from 'src/components/admin/age-rating/admin-age-rating-administration-container';
import AdminStarRatingAdministrationContainer from 'src/components/admin/star-rating/admin-star-rating-administration-container';
import AdminEngineAdministrationContainer from 'src/components/admin/engines/admin-engine-administration-container';
import AdminBookedTasksAdministrationContainer from 'src/components/admin/booked-tasks/admin-booked-tasks-administration-container';
import AdminReviewCategoryAdministrationContainer from 'src/components/admin/reviewcategories/admin-review-categories-administration-container';
import AdminGlobalSettingsAdministrationContainer from 'src/components/admin/global-settings/admin-global-settings-administration-container';
import AdminCustomerGroupAdministrationContainer from 'src/components/admin/customer-group/admin-customer-group-administration-container';
import AdminSchoolAdministrationContainer from 'src/components/admin/schools/admin-school-administration-container';
import AdminAssociationAdministrationContainer from 'src/components/admin/associations/admin-association-administration-container';
import HelpAdministration from 'src/components/admin/help/help-administration';
import HelpEntryAdministrationContainer from 'src/components/admin/help/entries/help-entry-administration-container';
import AdminHelpCategoryAdministrationContainer from 'src/components/admin/help/categories/admin-help-category-administration-container';
import TaskAdministrationContainer from 'src/components/taskadministration/task-administration-container';
import AdminCouponAdministrationContainer from 'src/components/admin/coupons/admin-coupon-administration-container';
import TaskExchangeContainer from 'src/components/taskexchange/task-exchange-container';
import OfferAdministrationContainer from 'src/components/offers/offer-administration-container';
import CustomerGroupDiscountAdministrationContainer from 'src/components/administration/customer-group-discount-administration-container';
import DiscountGroupAdministrationContainer from 'src/components/administration/discount-group-administration-container';
import CustomerAdministrationContainer from 'src/components/administration/customer/customer-administration-container';
import IntegrationAdministrationContainer from 'src/components/admin/integration/booking/integration-administration-container';
import ModuleAdministrationContainer from 'src/components/superadmin/modules/module-administration-container';
import CompanySettingsContainer from 'src/components/administration/company-settings/company-settings-container';
import AdminInvoiceAdministrationContainer from 'src/components/admin/invoices/admin-invoice-administration-container';
import AdminEventTicketNavigation from 'src/components/admin/event-tickets/navigation';
import AdminTicketEventAdministrationContainer from 'src/components/admin/event-tickets/events/ticket-event-administration-container';
import AdminTicketCollectionAdministrationContainer from 'src/components/admin/event-tickets/ticket-collections/ticket-collection-administration-container';
import TicketEventAdministrationContainer from 'src/components/administration/event-tickets/events/ticket-event-administration-container';
import EventCategoryAdministrationContainer from 'src/components/admin/event-tickets/categories/event-category-administration-container';
import TicketCollectionAdministrationContainer from 'src/components/administration/event-tickets/ticket-collections/ticket-collection-administration-container';
import EventTicketNavigation from 'src/components/administration/event-tickets/navigation';
import TripAdministration from 'src/components/trips/administration/TripNavigation';
import BusLocations from 'src/components/admin/buslocations/bus-locations';
import BusYield from 'src/components/admin/bus-yield/bus-yield';
import HelpContainer from 'src/components/help/help-container';
import Overview from 'src/components/superadmin/overview/overview';
import Companies from 'src/components/superadmin/companies/companies';
import Logins from 'src/components/superadmin/logins/logins';
import Login from 'src/components/account/login';
import LoginWithToken from 'src/components/account/login-with-token';
import Account from 'src/components/account/account';
import Error from 'src/components/nav/error';
import NotFound from 'src/components/nav/notfound';
import AdminTripCompanyAdministration from 'src/components/trips/admin/company/AdminTripCompanyAdministration';
import AdminTripNavigation from 'src/components/trips/admin/AdminTripNavigation';
import AdminTripOperatorAdministration from 'src/components/trips/admin/account/AdminTripOperatorAdministration';
import AdminTripCategoryAdministration from 'src/components/trips/admin/category/AdminTripCategoryAdministration';
import AdminTripAttributeAdministration from 'src/components/trips/admin/attribute/AdminTripAttributeAdministration';
import ProductAdministration from 'src/components/trips/administration/product/ProductAdministration';
import SeasonAdministration from 'src/components/trips/administration/season/SeasonAdministration';
import SeasonProductAdministration from 'src/components/trips/administration/season-product/SeasonProductAdministration';
import TripOperatingTool from 'src/components/trips/calendar/TripOperatingTool';
import TripBookingNavigation from 'src/components/trips/bookings/TripBookingNavigation';
import BookingAdministration from 'src/components/trips/bookings/BookingAdministration';
import SuperAdminNavigation from 'src/components/superadmin/SuperAdminNavigation';
import OperatingToolAdministration from 'src/components/admin/integration/operatingtool/OperatingToolAdministration';
import OperatingToolIntegrationDetails from 'src/components/admin/integration/operatingtool/details/OperatingToolIntegrationDetails';
import AdminNavigation from 'src/components/admin/AdminNavigation';
import AdminFleetAdministration from 'src/components/admin/fleets/AdminFleetAdministration';
import GlobalTemplateAdministration from 'src/components/admin/global-templates/GlobalTemplateAdministration';
import BookingIntegrationDetails from 'src/components/integration/BookingIntegrationDetails';
import SearchRequestsAdministration from 'src/components/admin/search-requests/SearchRequestsAdministration';
import MainCompanyBranchDataAdministration from 'src/components/admin/main-company-branch-data/MainCompanyBranchDataAdministration';
import InvoiceAdministration from 'src/components/administration/invoices/InvoiceAdministration';
import CustomerGroupSettingsAdministratorContainer from 'src/components/administration/customer-group-settings-administration-container';
import OperatorStoreAdministrationContainer from 'src/components/superadmin/modules/operator-store/operator-store-administration-container';
import OperatorStoreContainer from 'src/components/administration/operator-store/operator-store-container';
import AdminReferralAdministrationContainer from 'src/components/admin/referrals/admin-referral-administartion-container';
import TaskRatingAdministrationContainer from 'src/components/administration/task-ratings/task-rating-container';
import RatingReviewAdminContainer from 'src/components/admin/rating-reviews/admin-rating-reviews-administration-container';

export default (
    <Route path="/" component={App}>
        <IndexRoute component={Home} />

        {/* Operator / Dispatcher Routes */}

        <Route path="operating-tool" component={OperatingToolContainer} />
        <Route path="task-administration" component={TaskAdministrationContainer} />
        <Route path="task-exchange" component={TaskExchangeContainer} />
        <Route path="offer-administration" component={OfferAdministrationContainer} />
        <Route path="help" component={HelpContainer} />

        <Route path="administration" component={Administration}>
            <Route path="company" component={CompanyAdministrationContainer} />
            <Route path="buses" component={BusAdministrationContainer} />
            <Route path="categories" component={CategoryAdministrationContainer} />
            <Route path="calculation" component={CalculationAdministrationContainer} />
            <Route path="seasons" component={SeasonCalculationAdministrationContainer} />
            <Route path="countries" component={CountryCalculationAdministrationContainer} />
            <Route path="drivers" component={DriverAdministrationContainer} />
            <Route path="templates" component={TemplateAdministration} />
            <Route path="customer-groups" component={CustomerGroupDiscountAdministrationContainer} />
            <Route path="customer-group-settings" component={CustomerGroupSettingsAdministratorContainer} />
            <Route path="discount-groups" component={DiscountGroupAdministrationContainer} />
            <Route path="customers" component={CustomerAdministrationContainer} />
            <Route path="settings" component={CompanySettingsContainer} />
            <Route path="integration" component={BookingIntegrationDetails} />
            <Route path="store" component={OperatorStoreContainer} />
            <Route path="invoices" component={InvoiceAdministration} />
            <Route path="task-ratings" component={TaskRatingAdministrationContainer} />
        </Route>

        {/* Trip Operator Routes */}

        <Route path="trips/administration" component={TripAdministration}>
            <IndexRedirect to="company" />
            <Route path="company" component={CompanyAdministrationContainer} />
            <Route path="products" component={ProductAdministration} />
            <Route path="seasons" component={SeasonAdministration} />
            <Route path="seasons/:id" component={SeasonProductAdministration} />
        </Route>

        <Route path="trips" component={TripBookingNavigation}>
            <Route path="bookings" component={BookingAdministration} />
        </Route>

        <Route path="trips/calendar" component={TripOperatingTool} />

        {/* Admin Routes */}

        <Route path="admin" component={AdminNavigation}>
            <IndexRedirect to="company" />
            <Route path="fleets" component={AdminFleetAdministration} />
            <Route path="company" component={AdminCompanyAdministrationContainer} />
            <Route path="accounts" component={AdminAccountAdministrationContainer} />
            <Route path="customers" component={AdminCustomerAdministrationContainer} />
            <Route path="brands" component={AdminBrandAdministrationContainer} />
            <Route path="equipments" component={AdminEquipmentAdministrationContainer} />
            <Route path="age-rating" component={AdminAgeRatingAdministrationContainer} />
            <Route path="star-rating" component={AdminStarRatingAdministrationContainer} />
            <Route path="engines" component={AdminEngineAdministrationContainer} />
            <Route path="review-categories" component={AdminReviewCategoryAdministrationContainer} />
            <Route path="booked-tasks(?filter=:filter)" component={AdminBookedTasksAdministrationContainer} />
            <Route path="weighting" component={AdminGlobalSettingsAdministrationContainer} />
            <Route path="help-administration" component={HelpAdministration} />
            <Route path="help-entry-administration" component={HelpEntryAdministrationContainer} />
            <Route path="help-category-administration" component={AdminHelpCategoryAdministrationContainer} />
            <Route path="coupons" component={AdminCouponAdministrationContainer} />
            <Route path="customer-groups" component={AdminCustomerGroupAdministrationContainer} />
            <Route path="main-company-branch-data" component={MainCompanyBranchDataAdministration} />
            <Route path="invoices" component={AdminInvoiceAdministrationContainer} />
            <Route path="schools" component={AdminSchoolAdministrationContainer} />
            <Route path="associations" component={AdminAssociationAdministrationContainer} />
            <Route path="integrations/booking" component={IntegrationAdministrationContainer} />
            <Route path="integrations/booking/:id" component={BookingIntegrationDetails} />
            <Route path="integrations/operatingtool" component={OperatingToolAdministration} />
            <Route path="integrations/operatingtool/:id" component={OperatingToolIntegrationDetails} />
            <Route path="global-templates" component={GlobalTemplateAdministration} />
            <Route path="statistics/bus-locations" component={BusLocations} />
            <Route path="statistics/search-requests" component={SearchRequestsAdministration} />
            <Route path="referrals" component={AdminReferralAdministrationContainer} />
            <Route path="rating-reviews" component={RatingReviewAdminContainer} />
        </Route>

        <Route path="admin/event-tickets" component={AdminEventTicketNavigation}>
            <IndexRedirect to="events" />
            <Route path="events" component={AdminTicketEventAdministrationContainer} />
            <Route path="events/:id" component={AdminTicketCollectionAdministrationContainer} />
            <Route path="categories" component={EventCategoryAdministrationContainer} />
        </Route>

        <Route path="admin/trips" component={AdminTripNavigation}>
            <IndexRedirect to="companies" />
            <Route path="companies" component={AdminTripCompanyAdministration} />
            <Route path="accounts" component={AdminTripOperatorAdministration} />
            <Route path="categories" component={AdminTripCategoryAdministration} />
            <Route path="attributes" component={AdminTripAttributeAdministration} />
            <Route path="bookings" component={BookingAdministration} />
        </Route>

        {/* Super Admin Routes */}

        <Route path="superadmin" component={SuperAdminNavigation}>
            <IndexRedirect to="monitoring/entities" />
            <Route path="monitoring/entities" component={Overview} />
            <Route path="monitoring/companies" component={Companies} />
            <Route path="monitoring/logins" component={Logins} />
            <Route path="modules/store" component={OperatorStoreAdministrationContainer} />
            <Route path="modules/companies" component={ModuleAdministrationContainer} />
            <Route path="statistics/bus-yield" component={BusYield} />
        </Route>

        {/* Misc */}

        <Route path="event-tickets" component={EventTicketNavigation}>
            <IndexRedirect to="events" />
            <Route path="events" component={TicketEventAdministrationContainer} />
            <Route path="events/:id" component={TicketCollectionAdministrationContainer} />
        </Route>

        <Route path="account" component={Account} />
        <Route path="login-with-token(?token=:token&error=:error)" component={LoginWithToken} />
        <Route path="login(?error=:error)" component={Login} />
        <Route path="error(?error=:error)" component={Error} />
        <Route path="*" component={NotFound} />
    </Route>
);
