import React from 'react';
import { useTranslation } from 'react-i18next';
import { BASIC_SYSTEM_FEE, BASIC_SYSTEM_FEE_MODULE } from 'src/common';
import { CardIcon } from 'src/components/administration/operator-store/card-icon';
import { StoreModuleCard } from 'src/components/administration/operator-store/store-module-card';

interface BasicSystemFeeCardProps {
    registrationTOS: string;
    onClick: () => void;
}

export const BasicSystemFeeCard = ({ registrationTOS, onClick }: BasicSystemFeeCardProps) => {
    const { t } = useTranslation();

    if (!hasSystemFee(registrationTOS)) return null;

    return (
        <StoreModuleCard
            icon={<CardIcon type={BASIC_SYSTEM_FEE_MODULE} />}
            title={t(`modules_store.types.${BASIC_SYSTEM_FEE_MODULE}.title`)}
            description={t(`modules_store.types.${BASIC_SYSTEM_FEE_MODULE}.short_description`)}
            fee={BASIC_SYSTEM_FEE}
            actionComponent={
                <span className="required-text ">{t(`modules_store.types.${BASIC_SYSTEM_FEE_MODULE}.required`)}</span>
            }
            onClick={onClick}
        />
    );
};

// The monthly basic system fee was introduced in version 1.7 of the TOS. This does not always apply, it depends on whether the company was able to provide enough buses for us for the month.
const hasSystemFee = (version: string) => parseFloat(version.slice(1)) >= 1.7;
