import { connect } from 'react-redux';

import { deleteById, Entities, fetchAll, getEntities, patch } from 'src/common/index';
import OperatorStoreAdministration from 'src/components/superadmin/modules/operator-store/operator-store-administration';

const mapStateToProps = state => {
    return {
        storeModules: getEntities(state, Entities.STORE_MODULES),
    };
};

const OperatorStoreAdministrationContainer = connect(mapStateToProps, {
    deleteById,
    fetchAll,
    patch,
})(OperatorStoreAdministration);

export default OperatorStoreAdministrationContainer;
