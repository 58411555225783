import moment from 'moment';
import React from 'react';
import { TFunction } from 'i18next';
import Money from 'src/common/entity/basic-types/Money';
import { StoreModuleType } from 'src/components/administration/operator-store/types';
import { dateFormatter, priceFormatter } from 'src/utils/formatters';
import { SanitizedInnerHTML } from 'src/common';

interface ModuleLongDescriptionProps {
    type: StoreModuleType;
    fee: Money;
    transactionFee?: Money;
    t: TFunction;
}

export const ModuleLongDescription = ({ type, fee, transactionFee, t }: ModuleLongDescriptionProps) => {
    const nextBillingDate = moment().add(1, 'month').startOf('month');

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <Section>
                <h4>{t('common_phrases.description')}</h4>
                <p>
                    <SanitizedInnerHTML html={t(`modules_store.types.${type}.long_description`)} />
                </p>
            </Section>
            <Section>
                <h4>{t('modules_store.payment_details')}</h4>
                <div>
                    <p>
                        {t('modules_store.monthly_fee', {
                            fee: priceFormatter(fee),
                        })}
                    </p>
                    {transactionFee && (
                        <p>
                            {t('modules_store.transaction_fee', {
                                transactionFee: priceFormatter(transactionFee),
                            })}
                        </p>
                    )}
                    <p>
                        {t('modules_store.next_billing_date', {
                            date: dateFormatter(nextBillingDate),
                        })}
                    </p>
                </div>
            </Section>
        </div>
    );
};

const Section: React.FC = ({ children }) => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>{children}</div>
);
