import { TFunction } from 'i18next';
import React from 'react';
import { Entities } from 'src/common';
import Company from 'src/common/entity/company/Company';
import { ButtonColors } from 'src/utils/constants';
import { ModalProps } from 'src/utils/modal-action-wrapper';
import ConfirmDialog from '../misc/confirm-dialog';

type State = 'not_visible' | 'pending' | 'visible';

interface VisibilityStatusProps {
    busCompany: Company;
    openModal: (props: ModalProps) => void;
    t: TFunction;
    patch: any;
}

export const VisibilityStatus: React.FunctionComponent<VisibilityStatusProps> = ({
    t,
    busCompany,
    openModal,
    patch,
}) => {
    const status: State = busCompany.visible ? 'visible' : busCompany.visibilityRequested ? 'pending' : 'not_visible';
    const color = status === 'visible' ? 'btn-success' : status === 'pending' ? 'btn-warning' : 'btn-danger';

    const handleOpenModal = () => {
        openModal({
            component: ConfirmDialog,
            title: t(`request_visibility.${status}.title`),
            componentProps: {
                bodyText: t(`request_visibility.${status}.body`),
                proceedText: t(`request_visibility.${status}.proceed`),
                proceedButtonColor: status === 'not_visible' ? ButtonColors.SUCCESS : ButtonColors.NOTIFY,
                cancelText: t('common_phrases.abort'),
                cancelButtonColor: ButtonColors.NOTIFY,
                isNotification: status !== 'not_visible',
                onProceedDialog: () => {
                    if (status !== 'not_visible') return;
                    patch({ id: busCompany.id }, Entities.BUS_COMPANY, 0, undefined, undefined, 'requestVisibility');
                },
            },
            noButtons: true,
        });
    };

    return (
        <button className={`btn navbar-btn ${color}`} onClick={handleOpenModal}>
            {status === 'visible' ? (
                <span className="glyphicon glyphicon-eye-open" />
            ) : (
                <span className="glyphicon glyphicon-eye-close" />
            )}
            &nbsp;&nbsp;
            {t(`request_visibility.${status}.action`)}
        </button>
    );
};
