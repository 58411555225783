import React from 'react';
import { useTranslation } from 'react-i18next';

interface CardActionButtonProps {
    active: boolean;
    onClick: () => void;
}

export const CardActionButton = ({ onClick, active }: CardActionButtonProps) => {
    const { t } = useTranslation();

    return (
        <button
            className={`btn card-button ${active ? 'deactivate' : 'activate'}`}
            onClick={e => {
                e.stopPropagation();
                onClick();
            }}>
            {active ? t('modules_store.deactivate') : t('modules_store.activate')}
        </button>
    );
};
