import { CircularProgress, Divider } from 'material-ui';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { withTranslation } from 'react-i18next';
import {
    BASIC_SYSTEM_FEE,
    BASIC_SYSTEM_FEE_MODULE,
    Entities,
    getUpdatedEntity,
    Projection,
    SanitizedInnerHTML,
} from 'src/common/index';
import { BasicSystemFeeCard } from 'src/components/administration/operator-store/basic-system-fee-card';
import { CardActionButton } from 'src/components/administration/operator-store/card-action-button';
import { CardIcon } from 'src/components/administration/operator-store/card-icon';
import { ModuleLongDescription } from 'src/components/administration/operator-store/module-long-description';
import { StoreContainer } from 'src/components/administration/operator-store/store-container';
import { StoreModuleCard } from 'src/components/administration/operator-store/store-module-card';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { ButtonColors } from 'src/utils/constants';
import composeModalActions from 'src/utils/modal-action-wrapper';
import './css/styles.pcss';
import ConditionalDialog from 'src/components/misc/conditional-dialog';

class OperatorStore extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentDidMount() {
        this.fetchModules();
    }

    render() {
        const { t, ownCompany, allModules } = this.props;

        if (allModules.items.length === 0) return <CircularProgress />;

        const [activeModules, inactiveModules] = this.mergeStoreModulesAndModules(
            allModules.items[0].storeModules,
            allModules.items[0].modules,
        );

        return (
            <div>
                <legend className="legend">{t('modules_store.store')}</legend>
                <button
                    className="btn btn-xs btn-link btn-link-neutral"
                    style={{ marginBottom: 25 }}
                    onClick={this.openHowToUseModal}>
                    {t('modules_store.how_to_use')}
                </button>

                <StoreContainer title={t('modules_store.active_modules')}>
                    <BasicSystemFeeCard
                        registrationTOS={ownCompany.entity.registrationTOS}
                        onClick={() =>
                            this.handleCardClick({
                                type: BASIC_SYSTEM_FEE_MODULE,
                                fee: BASIC_SYSTEM_FEE,
                            })
                        }
                    />
                    {activeModules.map(module => (
                        <StoreModuleCard
                            key={module.type}
                            icon={<CardIcon type={module.type} />}
                            title={t(`modules_store.types.${module.type}.title`)}
                            description={t(`modules_store.types.${module.type}.short_description`)}
                            fee={module.fee}
                            actionComponent={
                                <CardActionButton
                                    active={module.active}
                                    onClick={() => this.handleActivateModule(module)}
                                />
                            }
                            onClick={() => this.handleCardClick(module)}
                        />
                    ))}
                </StoreContainer>

                {inactiveModules.length > 0 && (
                    <>
                        <Divider className="store-divider" />
                        <StoreContainer title={t('modules_store.inactive_modules')}>
                            {inactiveModules.map(module => (
                                <StoreModuleCard
                                    key={module.type}
                                    icon={<CardIcon type={module.type} />}
                                    title={t(`modules_store.types.${module.type}.title`)}
                                    description={t(`modules_store.types.${module.type}.short_description`)}
                                    fee={module.fee}
                                    actionComponent={
                                        <CardActionButton
                                            active={module.active}
                                            onClick={() => this.handleCardClick(module)}
                                        />
                                    }
                                    onClick={() => this.handleCardClick(module)}
                                />
                            ))}
                        </StoreContainer>
                    </>
                )}
            </div>
        );
    }

    // Override any default store module with a module that the admin has defined
    mergeStoreModulesAndModules(storeModules, modules) {
        const modulesMap = new Map(modules.map(m => [m.type, m]));

        // Overwrite storeModules with modules if the type is the same
        const mergedModules = storeModules.map(sm => modulesMap.get(sm.type) || sm);

        const remainingModules = modules.filter(m => !storeModules.some(sm => sm.type === m.type));

        const allModules = [...mergedModules, ...remainingModules].filter(
            m => m.type !== 'ARRIVAL_RETURN_FLAT_RATE_CALCULATION',
        );

        return [allModules.filter(m => m.active), allModules.filter(m => !m.active)];
    }

    handleActivateModule(module) {
        this.props.patch(
            { id: module.id, type: module.type },
            Entities.STORE_MODULES,
            this.props.selectedCompany,
            Projection.DEFAULT,
            () => this.handleSuccess(module),
            'activate',
        );
    }

    handleSuccess(module) {
        const successType = module.active ? 'deactivated' : 'activated';
        this.fetchModules();
        this.props.fetchOwnAccount();
        this.props.addNotification(
            this.props.t(`modules_store.success.${successType}.title`),
            this.props.t(`modules_store.success.${successType}.description`, {
                type: this.props.t(`modules_store.types.${module.type}.title`),
            }),
            'success',
            'tr',
        );
    }

    fetchModules() {
        this.props.search(
            Entities.STORE_MODULES,
            `fetchByCompanyId?companyId=${this.props.selectedCompany}`,
            this.props.selectedCompany,
            Projection.DEFAULT,
            true,
        );
    }

    handleCardClick(module) {
        const { t } = this.props;
        const isBasicSystemFee = module.type === BASIC_SYSTEM_FEE_MODULE;

        const proceedText = isBasicSystemFee
            ? t('common_phrases.ok')
            : module.active
              ? t('modules_store.deactivate')
              : t('modules_store.activate');

        const checkboxLabelText =
            module.fee.amount.toString() === '0'
                ? t('modules_store.checkbox_no_module_fee')
                : t('modules_store.checkbox_module_fee', { moduleFee: module.fee.amount });

        const isConditional = !isBasicSystemFee && !module.active;

        this.props.openModal({
            component: ConditionalDialog,
            title: (
                <div className="module-title">
                    <CardIcon type={module.type} />
                    <h4>{t(`modules_store.types.${module.type}.title`)}</h4>
                </div>
            ),
            componentProps: {
                isNotification: isBasicSystemFee,
                bodyText: (
                    <ModuleLongDescription
                        type={module.type}
                        fee={module.fee}
                        transactionFee={module.transactionFee}
                        t={t}
                    />
                ),
                proceedText,
                proceedButtonColor: ButtonColors.NOTIFY,
                cancelButtonColor: ButtonColors.DANGER,
                cancelText: t('common_phrases.abort'),
                checkboxLabelText: checkboxLabelText,
                isConditionalDialog: isConditional,
                onProceedDialog: () => (isBasicSystemFee ? null : this.handleActivateModule(module)),
                changeDisableProceedButton: value => {
                    this.changeDisableProceedButton(value);
                },
            },
            noButtons: true,
        });
    }

    openHowToUseModal() {
        this.props.openModal({
            component: ConfirmDialog,
            title: this.props.t('modules_store.how_to_use'),
            componentProps: {
                bodyText: <SanitizedInnerHTML html={this.props.t('modules_store.how_to_use_content')} />,
                proceedText: this.props.t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            noButtons: true,
        });
    }
}

export default withTranslation()(composeModalActions(OperatorStore));
