import { Toggle } from 'material-ui';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withTranslation } from 'react-i18next';
import { Entities } from 'src/common/index';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import OperatorStoreModuleEditModal from 'src/components/superadmin/modules/operator-store/operator-store-module-edit-modal';
import { ButtonColors } from 'src/utils/constants';
import { priceFormatter } from 'src/utils/formatters';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';

class OperatorStoreAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();

        this.state = {
            startedFetching: false,
        };
    }

    componentDidMount() {
        this.props.fetchAll(Entities.STORE_MODULES);
    }

    componentWillReceiveProps(nextProps) {
        const { storeModules } = nextProps;

        if (storeModules.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        return null;
    }

    render() {
        const { storeModules, t } = this.props;
        const finishFetching = this.state.startedFetching && !storeModules.isFetching;

        if (!finishFetching) return <div />;
        else if (storeModules.error) return <ErrorMessage object={storeModules} />;

        const tableData = [];
        if (finishFetching && storeModules.items.length > 0) {
            storeModules.items.map(module => {
                tableData.push({
                    id: module.id,
                    type: module.type,
                    fee: module.fee,
                    transactionFee: module.transactionFee,
                    active: module.active,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('modules_store.store')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.props.openModal({
                                component: OperatorStoreModuleEditModal,
                                componentProps: {
                                    isCreate: true,
                                    storeModules,
                                },
                                title: t('user_functionality.create', { entity: t('module') }),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('user_functionality.create', { entity: t('module') })}
                    </button>
                </legend>

                <BootstrapTable
                    ref={ref => (this.table = ref)}
                    data={tableData}
                    exportCSV={true}
                    striped={true}
                    hover={true}
                    condensed={true}
                    pagination={true}
                    options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                    searchPlaceholder={t('common_phrases.search')}
                    search={true}>
                    <TableHeaderColumn columnTitle={true} dataField="id" hidden={true} isKey={true} export={true}>
                        {t('common_phrases.id')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="type" dataSort={true}>
                        {t('common_phrases.type')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="fee"
                        width="130"
                        dataAlign="right"
                        dataFormat={priceFormatter}
                        csvFormat={priceFormatter}
                        dataSort={true}>
                        {t('booked_tasks.fee')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="actions"
                        width="90"
                        dataFormat={this.tableActionsFormatter}
                        export={false}>
                        {t('common_phrases.actions')}
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }

    tableActionsFormatter(_cell, module) {
        const { modules, t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: OperatorStoreModuleEditModal,
                            componentProps: {
                                id: module.id,
                                isCreate: false,
                            },
                            title: t('user_functionality.edit'),
                            mandatoryFields: true,
                        });
                    }}>
                    <span title={t('user_functionality.edit')} className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => this.openConfirmDeleteDialog(module)}>
                    <span title={t('user_functionality.delete')} className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }

    openConfirmDeleteDialog(module) {
        const { t, deleteById } = this.props;

        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: module,
                onProceedDialog: () => deleteById(module.id, Entities.STORE_MODULES),
                bodyText: t('user_functionality.confirm_delete_s', { entity: t('module') }),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: module.type,
            noButtons: true,
        });
    }
}

export default withTranslation()(composeModalActions(OperatorStoreAdministration));
