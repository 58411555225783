import { connect } from 'react-redux';

import { addNotification } from 'src/actions/notifications';
import { Entities, getEntities, getUpdatedEntity, patch, search } from 'src/common/index';
import OperatorStore from 'src/components/administration/operator-store/operator-store';
import { getOwnCompany } from 'src/selectors/bus-companies';
import { fetchOwnBusCompany } from 'src/actions/bus-companies';
import { fetchOwnAccount } from 'src/actions/accounts';

const mapStateToProps = state => {
    return {
        ownCompany: getOwnCompany(state),
        selectedCompany: state.selectedCompany,
        allModules: getEntities(state, Entities.STORE_MODULES, state.selectedCompany),
    };
};

const OperatorStoreContainer = connect(mapStateToProps, {
    addNotification,
    fetchOwnBusCompany,
    search,
    patch,
    fetchOwnAccount,
})(OperatorStore);

export default OperatorStoreContainer;
