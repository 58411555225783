import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Entities, fetchById, getUpdatedEntity, save, setMoneyCurrency, update } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import ModuleEditForm from 'src/components/superadmin/modules/module-edit-form';

class OperatorStoreModuleEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id, fetchById } = this.props;

        if (!isCreate) {
            console.log('bro fetch');
            fetchById(id, Entities.STORE_MODULES);
        } else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { storeModule } = nextProps;

        if (!this.state.startedFetching && storeModule.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { storeModules, storeModule, isCreate, selectedType, t } = this.props;
        const finishFetching = this.state.startedFetching && !storeModule.isFetching;

        if (!finishFetching) return <CircularProgress />;
        if (storeModule && storeModule.error) return <ErrorMessage object={storeModule} />;

        const initialValues = isCreate
            ? { active: false, fee: { amount: 0, currency: 'EUR' } }
            : {
                  id: storeModule.content.id,
                  type: storeModule.content.type,
                  fee: storeModule.content.fee,
                  transactionFee: storeModule.content.transactionFee,
                  active: false,
              };

        return (
            <ModuleEditForm
                modules={storeModules ? storeModules.items : []}
                module={storeModule ? storeModule.content : null}
                initialValues={initialValues}
                isCreate={isCreate}
                hideActive
                selectedType={selectedType}
                onSubmit={this.handleSubmit}
                t={t}
                ref={ref => (this.childForm = ref)}
            />
        );
    }

    handleSubmit(data) {
        const { isCreate, handleClose, update, save } = this.props;

        // To avoid the currency to be null, when there is a fee amount defined
        setMoneyCurrency(data.fee, 'EUR');
        setMoneyCurrency(data.transactionFee, 'EUR');

        if (!isCreate) update(data, Entities.STORE_MODULES);
        else save(data, Entities.STORE_MODULES);

        handleClose();
    }
}

const selector = formValueSelector('moduleEditForm');

const mapStateToProps = state => {
    return {
        storeModule: getUpdatedEntity(state, Entities.STORE_MODULES),
        selectedType: selector(state, 'type'),
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        save,
        update,
    },
    null,
    { withRef: true },
)(OperatorStoreModuleEditModal);
