import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Checkbox from 'material-ui/Checkbox';

class ConditionalDialog extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            disableProceedButton: true,
        };
    }

    proceedDialog() {
        const { onProceedDialog, event } = this.props;

        if (onProceedDialog) onProceedDialog(event);

        this.close();
    }

    cancelDialog() {
        const { onCancelDialog, event } = this.props;

        if (onCancelDialog && event) onCancelDialog(event);

        this.close();
    }

    close() {
        const { handleClose, closeDrawer } = this.props;

        if (closeDrawer) closeDrawer();
        handleClose();
    }

    changeDisableProceedButton(value) {
        this.setState({ disableProceedButton: value });
    }

    componentDidMount() {
        const { isConditionalDialog } = this.props;
        this.changeDisableProceedButton(isConditionalDialog);
    }

    render() {
        const {
            bodyText,
            proceedText,
            cancelText,
            proceedButtonColor,
            cancelButtonColor,
            isNotification,
            checkboxLabelText,
            isConditionalDialog,
        } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-md-12 voffset">{bodyText}</div>
                </div>

                <div className="row">
                    {isConditionalDialog && (
                        <div className="col-md-12 ">
                            <Checkbox
                                style={{ marginTop: '10px' }}
                                label={checkboxLabelText}
                                title={checkboxLabelText}
                                onCheck={(event, checked) => {
                                    this.setState({ disableProceedButton: !checked });
                                }}
                            />
                        </div>
                    )}
                    <div className="col-md-12 voffset">
                        <div className="form-group pull-right">
                            {!isNotification && (
                                <RaisedButton
                                    onClick={this.cancelDialog}
                                    label={cancelText}
                                    backgroundColor={cancelButtonColor}
                                    labelColor="#ffffff"
                                    className="confirm-dialog-2ndButton"
                                />
                            )}
                            <RaisedButton
                                onClick={this.proceedDialog}
                                label={proceedText}
                                backgroundColor={proceedButtonColor}
                                className="pull-right"
                                labelColor="#ffffff"
                                disabled={this.state.disableProceedButton}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConditionalDialog;
