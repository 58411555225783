import React from 'react';
import { useTranslation } from 'react-i18next';
import Money from 'src/common/entity/basic-types/Money';
import { priceFormatter } from 'src/utils/formatters';

interface StoreModuleCardProps {
    icon: React.ReactNode;
    title: string;
    description: string;
    fee: Money;
    actionComponent?: React.ReactNode;
    onClick: () => void;
}

export const StoreModuleCard = ({ icon, title, description, fee, actionComponent, onClick }: StoreModuleCardProps) => {
    const { t } = useTranslation();

    return (
        <div className="card" onClick={onClick}>
            <div className="module-title">
                {icon}
                <h4>{title}</h4>
            </div>

            <div>
                <p>{description}</p>
            </div>

            <div className="action-row">
                <span>
                    {t('modules_store.monthly_fee', {
                        fee: priceFormatter(fee),
                    })}
                </span>

                {actionComponent}
            </div>
        </div>
    );
};
