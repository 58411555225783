import React, { Component } from 'react';
import autoBind from 'react-autobind';
import NotificationSystem from 'react-notification-system';
import { connect } from 'react-redux';

import { addNotification } from 'src/actions/notifications';
import { withTranslation } from 'react-i18next';

/*
 * Creating a notification
 *
 * String title
 *       Title of the notification
 *
 * String message
 *       Message of the notification
 *
 * String level
 *       Level of the notification.
 *       Available: success, error, warning and info
 *
 * String position
 *       default: tr
 *       Position of the notification.
 *       Available: tr (top right), tl (top left), tc (top center),
 *       br (bottom right), bl (bottom left), bc (bottom center)
 *
 * Integer autoDismiss
 *       default: 5
 *       Delay in seconds for the notification go away.
 *       Set this to 0 to not auto-dismiss the notification
 *
 * Boolean dismissible
 *       default: true
 *       Set if notification is dismissible by the user.
 *
 * Object action
 *       Add a button with label and callback function (callback is optional).
 *
 * Function onAdd
 *       A callback function that will be called when the notification is successfully added.
 *
 * Function onRemove
 *       A callback function that will be called when the notification is about to be removed.
 *
 *Integer/String uid
 *       Overrides the internal uid. Useful if you are managing your notifications id.
 *       Notifications with same uid won't be displayed.
 * */

class Notifications extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentWillReceiveProps(nextProps, oldProps) {
        const { t } = nextProps;
        const { title, message, level, position, error } = nextProps.notifications;

        if (error) {
            this.notificationSystem.addNotification({
                title: t('error', { error: error.code }),
                message: error.message ? t('notifications.error', { message: error.message }) : '',
                level: 'error',
                position: 'tr',
                autoDismiss: 8,
                uid: `${error.code}_${error.message}`,
            });
        } else if (level && position) {
            this.notificationSystem.addNotification({
                title: title,
                message: message,
                level: level,
                position: position,
                autoDismiss: 8,
                uid: `${title}_${message}_${level}_${position}`,
            });
        }
    }

    render() {
        return <NotificationSystem ref={ref => (this.notificationSystem = ref)} />;
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications,
    };
};

export default withTranslation()(connect(mapStateToProps, { addNotification })(Notifications));
